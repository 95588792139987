import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Jumbotron from "react-bootstrap/Jumbotron";
import TermsComponent from "../shared/termsComponent";

const renderIndex = () => {
  return (
    <div>
      <Row>
        <Col xs={6}>
          <Jumbotron style={{ height: "120vh" }}>
            <h3>FAQ</h3>
            <hr></hr>
            <br />
            <ul className="text-left faq">
              <li>
                <h5>Why does my server keep shutting down?</h5>
              </li>
              Servers are put to sleep after a 1/2/3 hours of inactivity (depending on subscription plan). Unfortunately
              not all activity is measurable by our monitors, and so we support{" "}
              <a href="https://molten-hosting.notion.site/Discord-Webhooks-c4f49b98ff524bb596073d307e52ed0e">
                Discord webhooks
              </a>{" "}
              to help notify users before their server is set to be shut down.
              <li>
                <h5>How do I upgrade my account?</h5>
              </li>
              You can upgrade your account in the upper right hand corner underneath the My Account dropdown. You can
              also reach out to <a href="mailto:support@moltenhosting.com">support@moltenhosting.com</a> or the Discord
              and ask to be upgraded.
              <li className="mt-3">
                <h5>Why is my server asking for a password?</h5>
              </li>
              We set a default password when we create your server. This protects it until you're ready to set it up for
              yourself. You can find the default password by viewing your server details.
              <li className="mt-3">
                <h5>Can I change my default password?</h5>
              </li>
              Yes you can! You can set it via the FoundryVTT configuration menu on your server. If you have lost your
              password, please submit a support ticket and we can help you reset it.
              <li className="mt-3">
                <h5>How do I upload/manage/delete my files?</h5>
              </li>
              Head over to your server details page for the connection details. We provide step by step instructions in
              our <a href="https://www.notion.so/Managing-Server-Files-df96be0dcde74775994a86c844ba1a4e">user guide</a>.
              <li className="mt-3">
                <h5>How do I let my players wake my server?</h5>
              </li>
              We provide a "magic URL" in your server details that you can distribute to your players. When they visit
              this link, it will wake the server and take it to the setup page or default world.
              <li className="mt-3">
                <h5>
                  I imported my world via WEBDAV/CloudCommander, but I don't see anything or I'm getting a 502 error!
                  What gives?
                </h5>
              </li>
              The Foundry process needs a restart after you add worlds to the data directory. Click the "Restart
              Foundry" button in your server detail page. That will restart Foundry but leave your server running.
            </ul>
          </Jumbotron>
        </Col>
        <Col xs={6}>
          <Jumbotron style={{ height: "120vh" }}>
            <h3>News</h3>
            <hr></hr>
            <h4 style={{ color: "#FD4A19" }}>February 4, 2024</h4>
            <p className="text-left">
              <br />
              Happy New Year all! I have an important announcement. Last year AWS notified that they would start
              charging for the use of IPv4 addresses assigned to servers. Based on MoltenHosting's current usage this
              would amount to ~$1200/mo in additional charges to the business and would make operating MoltenHosting
              significantly more expensive and thus less feasible for me. <br />
              <br />
              After some careful consideration and research I believe that I am able to remove IPv4 public IPs from
              MoltenHosting servers with minor impact to you all and your games. Unfortunately that means I will have to
              retire the FTPS service that has been the standard file management solution since day 1 at MoltenHosting.
              WEBDAV and CloudCommander will continue to be available and the recommended mechanism for managing files.
              <br />
              <br />
              <b style={{ color: "#FD4A19" }}>
                MoltenHosting will be doing a major upgrade to the entire system starting Monday, February 5, 2024 2:00
                PM US/Central and I expect it to take roughly 30-45 minutes. During this time servers that are running
                will not be impacted, but server startup and stop may be impacted.
              </b>
              <br />
              <br />
              As always, thank you for trusting me to host your games!{" "}
              <p style={{ color: "#FD4A19", paddingLeft: 30 }}> - Mike (VersaceHovercraft)</p>
            </p>
            <br />
            <p className="text-left">
              <h4>FTPS Retirement FAQ</h4>
              Does this change my game files or how my players connect to the game?
              <br />
              <b style={{ color: "#FD4A19" }}>Nope! Game servers and how your players connect is unchanged.</b>
              <br />
              <br />
              How do I know if I will be impacted by this change?
              <br />
              <b style={{ color: "#FD4A19" }}>
                Chances are, if you haven't heard of or used FTP(S) before, then you won't be impacted. I've made sure
                these changes are backwards compatable as possible.
              </b>
              <br />
              <br />
              What should I use for managing files?
              <br />
              <b style={{ color: "#FD4A19" }}>
                I really heavily recommend using CloudCommander for managing your files, it really is the easiest and
                most friendly. However, I use CyberDuck for testing WebDav and can say that it works very well!
              </b>
              <br />
              <br />I mount the FTPS server as a Network Drive in macOS/Windows, will I be impacted?
              <br />
              <b style={{ color: "#FD4A19" }}>
                Yes, unfortunately. However, you can follow the exact same process you followed to setup FTPS as a
                network drive but use WEBDAV instead and it should work the exact same.
              </b>
              <br />
              <br />
              My players/myself don't have IPv6 support from our ISP, are we still able to use MoltenHosting?
              <br />
              <b style={{ color: "#FD4A19" }}>
                Yes! Nothing will change how you connect to games, and you do not need to have IPv6 support.
              </b>
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </div>
  );
};

export default function IndexComponent(props) {
  return props.user.settings.termsAccepted ? (
    renderIndex()
  ) : (
    <TermsComponent user={props.user} getUser={props.getUser} />
  );
}
