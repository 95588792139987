import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import { Redirect } from "react-router-dom";
import env from "../env";
import { useAuth0 } from "@auth0/auth0-react";
import { userIsPro, userIsElite } from "../services/userService";
import CopyButtonComponent from "./copyButtonComponent";
import moment from "moment";
import { useStripe } from "@stripe/react-stripe-js";
import { BrowserRouter as Router, Link, useLocation } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";

const DDB_ACTIVE_STATE = "ACTIVE";

const statusMap = {
  RUNNING: "RUNNING",
  NOT_RUNNING: "STOPPED",
  STOPPING: "STOPPING",
  STARTING: "STARTING",
  RESTORING_BACKUP: "RESTORING BACKUP",
  DELETING: "DELETING",
  CREATING: "CREATING"
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function StackDetails(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [toHome, setToHome] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [deleteConfirmationValue, setDeleteConfirmationValue] = useState("");
  const [serverNameMatches, setServerNameMatches] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [stopPending, setStopPending] = useState(false);
  const [activatePending, setActivatePending] = useState(false);
  const [exportModalText, setExportModalText] = useState("This will use your 1 game data export allowed per day.");
  const [disableModalButton, setDisableModalButton] = useState(false);
  const [exportButtonDisableOverride, setExportButtonDisableOverride] = useState(false);
  const [restartPending, setRestartPending] = useState(false);
  const [startDisabled, setStartDisabled] = useState(false);
  const [stopDisabled, setStopDisabled] = useState(false);
  const [restoreDisabled, setRestoreDisabled] = useState(false);
  const [redirectToStack, setRedirectToStack] = useState(false);
  const [cloneStackName, setCloneStackName] = useState("");
  const [cloneDisabled, setCloneDisabled] = useState(false);
  const [newWebhookUrl, setNewWebhookUrl] = useState("");
  const [showSnapshotDialog, setShowSnapshotDialog] = useState(false);
  const [snapshotName, setSnapshotName] = useState("");

  const stripe = useStripe();
  let query = useQuery();

  const restoreSnapshot = async (stack, snapshot) => {
    setRestoreDisabled(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
      audience: env.AUD,
      method: "PATCH",
      body: JSON.stringify({ snapshotId: snapshot.snapshotId }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }).then(async (response) => {
      await new Promise((r) => setTimeout(r, 1000));
      props.getStacks();
      setRestoreDisabled(false);
    });
  };

  const createSnapshot = async (stack) => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
      audience: env.AUD,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        name: snapshotName
      })
    });
    setShowSnapshotDialog(false);
  };

  const createExport = async (e, stack) => {
    setSubmitted(true);
    setDisableModalButton(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    const response = await fetch(env.API_URL + `api/server_export`, {
      audience: env.AUD,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({ stackName: stack.stackName })
    });

    setSubmitted(false);

    if (response.ok) {
      setExportModalText(
        "Your game data export has been requested. This may take a few minutes based on the amount of data. A link will appear at the bottom of this table when it is ready."
      );
      setExportButtonDisableOverride(true);

      await new Promise((r) => setTimeout(r, 1000));
      props.getStacks();
    }
  };

  const renderSnapshotRows = (stack) => {
    stack.backups.sort((a, b) => {
      if (a.migratedStartTime != null) {
        return a.migratedStartTime.diff(b.startTime);
      }

      return a.startTime.diff(b.startTime);
    });

    stack.backups.reverse();
    return stack.backups.map((value, index) => {
      var backupType;
      if (value.backupType === null) {
        backupType = "---";
      } else if (value.backupType === "USER_CREATED") {
        backupType = "MANUAL";
      } else {
        backupType = value.backupType;
      }

      return (
        <tr key={index.toString()}>
          <td>{value.startTime.format("MMM Do YYYY, h:mm a")}</td>
          <td>{value.SnapshotName === null || value.SnapshotName === undefined ? "---" : value.SnapshotName}</td>
          <td>{backupType}</td>
          <td>
            <Button
              variant="danger"
              onClick={() => restoreSnapshot(stack, value)}
              disabled={!["LIVE", "STOPPED"].includes(statusMap[stack.serverStatus]) || restoreDisabled}
            >
              Restore
            </Button>
          </td>
        </tr>
      );
    });
  };

  const handleWakeClick = async (stack) => {
    setStartDisabled(true);
    fetch("https://" + stack.hostname + "/start-server", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ code: stack.stackPassword })
    }).then((response) => {
      window.open("/lobby?status=JUST_STARTED&server=" + stack.stackName, "_blank");
      props.getStacks();
    });
    setStartDisabled(false);
  };

  const handleStopClick = async (stack) => {
    setStopDisabled(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
      audience: env.AUD,
      method: "PATCH",
      body: JSON.stringify({ serverStatus: "NOT_RUNNING" }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });

    setStopPending(true);
    await new Promise((r) => setTimeout(r, 3000));
    props.getStacks();
    await new Promise((r) => setTimeout(r, 2000));
    setStopPending(false);
    setStopDisabled(false);
  };

  const handleRestartClick = async (stack, upgrade, version) => {
    setRestartPending(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    let body;

    if (upgrade === true) {
      body = JSON.stringify({ doctor: "true", version: version });
    } else {
      body = JSON.stringify({ doctor: "true" });
    }

    await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
      audience: env.AUD,
      method: "PATCH",
      body: body,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });
    await new Promise((r) => setTimeout(r, 3000));
    setRestartPending(false);
  };

  const handleActivateServer = async (e, stack) => {
    setActivatePending(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
      audience: env.AUD,
      method: "PATCH",
      body: JSON.stringify({ userStackStatus: DDB_ACTIVE_STATE }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });

    await new Promise((r) => setTimeout(r, 3000));
    props.getStacks();
    await new Promise((r) => setTimeout(r, 2000));

    setActivatePending(false);
  };

  const handleDeleteConfirmationSubmit = async (e, stack) => {
    if (deleteConfirmationValue !== stack.stackName) {
      setServerNameMatches(false);
      return;
    }
    setSubmitted(true);

    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
      audience: env.AUD,
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });

    await new Promise((r) => setTimeout(r, 3000));
    props.getStacks();
    await new Promise((r) => setTimeout(r, 2000));

    setSubmitted(false);

    setShowDeleteModal(false);
    setToHome(true);
  };

  const handleClone = async (stack) => {
    setCloneDisabled(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + "api/servers", {
      audience: env.AUD,
      method: "POST",
      body: JSON.stringify({
        clone: true,
        foundryVersion: stack.foundryVersion,
        serverName: stack.stackName,
        region: stack.region
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });
    await new Promise((r) => setTimeout(r, 3000));
    props.getStacks();
    setCloneStackName(`${stack.stackName}-copy`);
    await new Promise((r) => setTimeout(r, 2000));

    setRedirectToStack(true);
  };

  const computeGb = (bytes, decimals = 2) => {
    // Stolen from https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const renderSpinner = () => {
    return (
      <tr>
        <td colSpan="2">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </td>
      </tr>
    );
  };

  const renderServerUrl = (stack) => {
    return (
      <tr>
        <th>Url</th>
        <td>
          {stack.serverStatus === "CREATING" ? (
            "UNKNOWN"
          ) : (
            <a href={"https://" + stack.hostname} target="_blank" rel="noopener noreferrer">
              {"https://" + stack.hostname}
            </a>
          )}
        </td>
      </tr>
    );
  };

  const renderServerStartupUrl = (stack) => {
    return (
      <tr>
        <th>
          Server Startup Url (
          <a href="https://www.notion.so/How-Can-My-Players-Wake-The-Server-b268e35de53543839f97597fc5f0b950">
            Magic URL
          </a>
          )
        </th>
        <td>
          {stack.serverStatus === "CREATING" ? (
            "UNKNOWN"
          ) : (
            <a
              href={"https://" + stack.hostname + "?s=" + stack.serverStartupSig}
              target="_blank"
              rel="noopener noreferrer"
            >
              {"https://" + stack.hostname + "?s=" + stack.serverStartupSig}
            </a>
          )}
        </td>
      </tr>
    );
  };

  const renderFtpDetails = (stack) => {
    const webDavHostname = `https://${stack.stackName}.webdav.moltenhosting.com`;
    const ftpUsername = `foundry-ftp`;
    const cloudCommander = `https://${stack.stackName}.files.moltenhosting.com`;
    return (
      <tr>
        <th>
          File Management Details
          <p className="text-muted">Note: Server must be live to use file management.</p>
        </th>
        <td>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tbody>
              <tr>
                <td>File Manager Username:</td>
                <td>
                  <code>{ftpUsername}</code>
                </td>
                <td>
                  <CopyButtonComponent text={ftpUsername} />
                </td>
              </tr>
              <tr>
                <td>File Manager Password:</td>
                <td>
                  <code>{stack.ftp.password}</code>
                </td>
                <td>
                  <CopyButtonComponent text={stack.ftp.password} />
                </td>
              </tr>
              <tr>
                <td>CloudCommander</td>
                <td>
                  <a href={cloudCommander}>
                    <code>{cloudCommander}</code>
                  </a>
                </td>
                <td>
                  <CopyButtonComponent text={cloudCommander} />
                </td>
              </tr>
              <tr>
                <td>WebDav Host:</td>
                <td>
                  <code>{webDavHostname}</code>
                </td>
                <td>
                  <CopyButtonComponent text={webDavHostname} />
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  };

  const renderFileExportLink = (stack) => {
    const inProgress = !stack.exportTimestamp && stack.lastExportRequestTimestamp;
    return (
      <tr>
        <th>Game Data Export</th>
        <td>
          {inProgress ? (
            "Your export request is being processed."
          ) : (
            <span>
              <a href={stack.exportUrl}>{"Click here to download"}</a>
              {" - Generated on"} {moment.unix(stack.exportTimestamp).local().format("MMMM D, YYYY")}
            </span>
          )}
        </td>
      </tr>
    );
  };

  const renderStorageInfo = (stack) => {
    let gbUsed = 0;
    let gbFree = 0;
    let usedPercent = 0;
    let barStyle = "info";

    const displayVolumeInfo = stack.dataVolume.total != null && stack.dataVolume.free != null;

    if (displayVolumeInfo) {
      const bytesUsed = stack.dataVolume.total - stack.dataVolume.free;
      gbUsed = computeGb(bytesUsed, 1);
      gbFree = computeGb(stack.dataVolume.total, 0);

      usedPercent = (bytesUsed / stack.dataVolume.total) * 100;

      if (usedPercent > 50 && usedPercent < 80) {
        barStyle = "warning";
      } else if (usedPercent >= 80) {
        barStyle = "danger";
      }
    }

    return (
      <tr>
        <th>Storage</th>
        <td>
          <ProgressBar striped variant={barStyle} now={usedPercent} />
          {displayVolumeInfo ? `${gbUsed} / ${gbFree}` : "UNKNOWN"}
          <br />
          Last Updated:{" "}
          {stack.dataVolume.lastUpdated != null
            ? stack.dataVolume.lastUpdated.format("MMM Do YYYY, h:mm a")
            : "UNKNOWN"}
          <br />
          <small>Available space is updated every 5 minutes if your server is running.</small>
        </td>
      </tr>
    );
  };

  const renderWebhookContent = (stack) => {
    const testWebhook = (url) => {
      const resp = fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          content: "This is a MoltenHosting test message!  It works!",
          embeds: null
        })
      });
    };

    const setWebhook = async (url) => {
      var expression = /https:\/\/discord.com\/api\/webhooks\/.*\/.*/gi;
      var regex = new RegExp(expression);

      if (url && !url.match(regex)) {
        alert("Please enter a valid Discord webhook.");
        return;
      }

      const accessToken = await getAccessTokenSilently({ audience: env.AUD });
      await fetch(env.API_URL + `/api/servers/${stack.stackName}`, {
        audience: env.AUD,
        method: "PATCH",
        body: JSON.stringify({ webhookUrl: newWebhookUrl }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        }
      });

      props.getStacks();
    };

    return (
      <tr>
        <th>
          <FaDiscord className="mr-2" />
          Discord Webhook
        </th>
        {stack.webhookUrl ? (
          <td>
            <Button variant="primary" onClick={(e) => testWebhook(stack.webhookUrl)}>
              Test
            </Button>
            <Button
              variant="danger"
              className="ml-2"
              onClick={(e) => {
                setNewWebhookUrl("");
                setWebhook(null);
              }}
            >
              Delete
            </Button>
          </td>
        ) : (
          <td>
            <InputGroup className="mb-3">
              <FormControl
                placeholder="Webhook URL"
                aria-label="Webhook URL"
                aria-describedby="basic-addon2"
                onChange={(e) => setNewWebhookUrl(e.target.value)}
              />
              <Button variant="success" id="button-addon2" onClick={(e) => setWebhook(newWebhookUrl)}>
                Save
              </Button>
            </InputGroup>
          </td>
        )}
      </tr>
    );
  };

  const serverControls = (stack) => {
    const allowDeletion = stack.serverStatus === "RUNNING" || stack.serverStatus === "NOT_RUNNING";
    const noExportWithinADay =
      !stack.lastExportRequestTimestamp || moment().diff(moment.unix(stack.lastExportRequestTimestamp), "hours") > 23;

    const allowExport = noExportWithinADay && stack.serverStatus === "RUNNING";

    return stack.userStackStatus === DDB_ACTIVE_STATE ? (
      <tr>
        <th>Controls</th>
        <td>
          <div className="ml-2">
            <Button
              hidden={!(stack.serverStatus === "NOT_RUNNING" && stack.userStackStatus === "ACTIVE")}
              onClick={(e) => handleWakeClick(stack)}
              disabled={startDisabled}
              variant="success"
              className="ml-2"
            >
              Start
            </Button>
            <Button
              hidden={!(stack.serverStatus === "RUNNING")}
              onClick={(e) => handleStopClick(stack)}
              variant="warning"
              className="ml-2"
              disabled={stopDisabled || stopPending}
            >
              {stopPending ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Stop"
              )}
            </Button>
            {userIsElite(props.user) ? (
              <Button variant="secondary" className="ml-2" onClick={() => setShowSnapshotDialog(true)}>
                Create Backup
              </Button>
            ) : null}
            {userIsElite(props.user) && props.stacks.length < 2 ? (
              <Button variant="light" className="ml-2" onClick={() => handleClone(stack)} disabled={cloneDisabled}>
                {cloneDisabled ? (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                ) : (
                  "Clone Server"
                )}
              </Button>
            ) : null}
            {allowExport ? (
              <Button variant="secondary" className="ml-2" onClick={(e) => setShowExportModal(true)}>
                Export Game Data
              </Button>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">
                    {!noExportWithinADay
                      ? "You have exceeded your limit, you can request another export " +
                        moment().to(moment.unix(stack.lastExportRequestTimestamp).add(23, "hours"))
                      : "You can only export data from a server that is running."}
                  </Tooltip>
                }
              >
                <Button variant="secondary" className="ml-2" disabled={true}>
                  Export Game Data
                </Button>
              </OverlayTrigger>
            )}
            <Button
              hidden={!(stack.serverStatus === "RUNNING")}
              onClick={(e) => handleRestartClick(stack)}
              variant="outline-warning"
              className="ml-2"
            >
              {restartPending ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Restart Foundry"
              )}
            </Button>
            {allowDeletion ? (
              <Button variant="outline-danger" className="ml-2" onClick={(e) => setShowDeleteModal(true)}>
                Delete
              </Button>
            ) : (
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-disabled">You can only delete a server that is running or stopped.</Tooltip>
                }
              >
                <Button className="ml-2" variant="outline-danger" disabled={true}>
                  Delete
                </Button>
              </OverlayTrigger>
            )}
            <br
              hidden={
                stack.serverStatus != "RUNNING" && (stack.foundryVersion == "v11" || stack.foundryVersion == "v12")
              }
            />
            <br
              hidden={
                stack.serverStatus != "RUNNING" && (stack.foundryVersion == "v11" || stack.foundryVersion == "v12")
              }
            />
            <Button
              hidden={stack.serverStatus != "RUNNING" || stack.foundryVersion == "v11" || stack.foundryVersion == "v12"}
              onClick={(e) => handleRestartClick(stack, true, "11")}
              variant="success"
              className="ml-2"
            >
              Upgrade to v11
            </Button>
            <Button
              hidden={stack.serverStatus != "RUNNING" || stack.foundryVersion == "v12"}
              onClick={(e) => handleRestartClick(stack, true, "12")}
              variant="success"
              className="ml-2"
            >
              Upgrade to v12
            </Button>
          </div>
        </td>
      </tr>
    ) : (
      <tr>
        <th>Controls</th>
        <td>
          <Button onClick={(e) => handleActivateServer(e, stack)} disabled={activatePending}>
            {activatePending ? (
              <Spinner as="span" className="ml-2" animation="border" size="sm" role="status" aria-hidden="true" />
            ) : (
              "Activate"
            )}
          </Button>{" "}
          <Button variant="outline-danger" className="ml-2" onClick={(e) => setShowDeleteModal(true)}>
            Delete
          </Button>
        </td>
      </tr>
    );
  };

  const renderDetails = () => {
    let stack = props.stack;

    const modifying =
      stack.serverStatus === "CREATING" ||
      stack.serverStatus === "UPDATING_VERSION" ||
      stack.serverStatus === "RESTORING_BACKUP" ||
      stack.serverStatus === "DELETING";

    const creating = stack.serverStatus === "CREATING";

    const running = stack.serverStatus === "RUNNING";

    return (
      <Row>
        <Col>{redirectToStack ? <Redirect to={`/server/${cloneStackName}`} /> : null}</Col>
        <Col xs={8}>
          <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Form.Group onChange={(e) => setDeleteConfirmationValue(e.target.value)}>
              <Modal.Body>
                <Form.Text>To confirm deleting this server, enter the server name below</Form.Text>
                <br />
                <Form.Control type="" placeholder="Server Name" isInvalid={!serverNameMatches}></Form.Control>
                <Form.Control.Feedback type="invalid">Does not match server name</Form.Control.Feedback>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                  Close
                </Button>
                <Button
                  variant="outline-danger"
                  type="submit"
                  onClick={(e) => handleDeleteConfirmationSubmit(e, stack)}
                  disabled={submitted}
                >
                  {submitted ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    "Delete"
                  )}
                </Button>
              </Modal.Footer>
            </Form.Group>
          </Modal>
          <Modal show={showExportModal} onHide={() => setShowExportModal(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Game Data Export Confirmation</Modal.Title>
            </Modal.Header>
            <Form.Group>
              <Modal.Body>
                <Form.Text>{exportModalText}</Form.Text>
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowExportModal(false)}>
                  Close
                </Button>
                <Button
                  variant="outline-warning"
                  type="submit"
                  onClick={(e) => createExport(e, stack)}
                  disabled={disableModalButton}
                >
                  {submitted ? (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                  ) : (
                    "Export Data"
                  )}
                </Button>
              </Modal.Footer>
            </Form.Group>
          </Modal>

          <Modal show={showSnapshotDialog} onHide={() => setShowSnapshotDialog(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Name Snapshot</Modal.Title>
            </Modal.Header>
            <Form.Group>
              <Modal.Body>
                <Form.Control
                  type=""
                  placeholder="Backup Name"
                  onChange={(e) => setSnapshotName(e.target.value)}
                ></Form.Control>
                <br />
                <Button
                  variant="outline-warning"
                  type="submit"
                  onClick={(e) => createSnapshot(stack)}
                  disabled={!showSnapshotDialog}
                  centered
                >
                  Create
                </Button>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Form.Group>
          </Modal>

          <h3>Details</h3>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <th>Server Name</th>
                <td>{stack.stackName}</td>
              </tr>
              <tr>
                <th>Server Status</th>
                <td>{statusMap[stack.serverStatus]}</td>
              </tr>
              <tr>
                <th>Region</th>
                <td>{stack.region.toUpperCase()}</td>
              </tr>
              {userIsPro ? renderWebhookContent(stack) : null}
              {creating ? (
                <tr>
                  <td colSpan="2">
                    <Alert variant="primary">Server creation takes around 5-10 minutes. Sit tight!</Alert>
                  </td>
                </tr>
              ) : null}
              {modifying ? renderSpinner() : null}
              {!modifying ? serverControls(stack) : null}
              {!modifying ? renderStorageInfo(stack) : null}
              {!modifying ? renderServerUrl(stack) : null}
              {!modifying ? renderServerStartupUrl(stack) : null}
              {!modifying ? (
                <tr>
                  <th>Default Administrator Password</th>
                  <td>
                    <code className="mr-2">{stack.stackPassword}</code>
                    <CopyButtonComponent text={stack.stackPassword} />
                  </td>
                </tr>
              ) : null}
              {!modifying && running ? renderFtpDetails(stack) : null}
              {stack.lastExportRequestTimestamp ? renderFileExportLink(stack) : null}
            </tbody>
          </Table>
          <br />
          <h3 hidden={modifying}>Backups</h3>
          <Table striped bordered hover hidden={modifying}>
            <thead>
              <tr>
                <th>Date</th>
                <th>Name</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>{renderSnapshotRows(stack)}</tbody>
          </Table>
        </Col>
        <Col></Col>
      </Row>
    );
  };

  return toHome ? <Redirect to="/" /> : renderDetails();
}
