import React from "react";
import NewServerForm from "./newServerFrom";
import Alert from "react-bootstrap/Alert";
import Jumbotron from "react-bootstrap/Jumbotron";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function NewStack(props) {
  return (
    <Row>
      <Col></Col>
      <Col lg={5}>
        <Jumbotron>
          <h2>Create New Server</h2>
          <br />
          <Alert variant="warning">
            Make sure you have a <Alert.Link href="https://foundryvtt.com/purchase/">Foundry VTT License</Alert.Link>{" "}
            before creating a server.
          </Alert>
          <br />
          <NewServerForm getStacks={props.getStacks} stacks={props.stacks} user={props.user} />
        </Jumbotron>
      </Col>
      <Col></Col>
    </Row>
  );
}
