import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function CopyButtonComponent(props) {
  const [show, setShow] = useState(false);

  const handleClick = async () => {
    navigator.clipboard.writeText(props.text);
    setShow(true);
    await new Promise((r) => setTimeout(r, 1000));
    setShow(false);
  };

  return (
    <OverlayTrigger trigger="click" placement="bottom" show={show} overlay={<Tooltip>Copied!</Tooltip>}>
      <FontAwesomeIcon icon="copy" onClick={handleClick} />
    </OverlayTrigger>
  );
}
