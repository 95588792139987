import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const useApi = (url, options = {}, dataDefault = {}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [state, setState] = useState({
    error: null,
    loading: true,
    data: dataDefault
  });
  const [refreshIndex, setRefreshIndex] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const { audience, scope, ...fetchOptions } = options;
        const accessToken = await getAccessTokenSilently({ audience, scope });
        const response = await fetch(url, {
          ...fetchOptions,
          headers: {
            ...fetchOptions.headers,
            Authorization: `Bearer ${accessToken}`
          }
        });
        if (!response.ok) {
          setState({
            ...state,
            data: null,
            error: response,
            loading: false
          });
        } else {
          setState({
            ...state,
            data: await response.json(),
            error: null,
            loading: false
          });
        }
      } catch (error) {
        setState({
          ...state,
          error,
          loading: false
        });
      }
    })();
  }, [refreshIndex]);

  return {
    ...state,
    refresh: () => setRefreshIndex((previousValue) => previousValue + 1)
  };
};
