import React, { useState, useRef } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import Table from "react-bootstrap/Table";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaInfoCircle } from "react-icons/fa";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../env";

function Dataonly(props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const handleClose = () => setShowConfirmModal(false);
  const [show, setShow] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [showCardError, setShowCardError] = useState(false);
  const [spinnerHidden, setSpinnerHidden] = useState(true);
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  const stackElements = props.stacks.map((stack) => {
    return (
      <tr>
        <td>{stack.stackName}</td>
        <td>
          <a href={stack.exportUrl}>Click here to download</a>
        </td>
      </tr>
    );
  });

  const handleStripePortalClick = async () => {
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });

    fetch(env.API_URL + "api/billing", {
      audience: env.AUD,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    })
      .then((response) => response.json())
      .then((data) => {
        window.location.href = data.url;
      });
  };

  const reactivateSubscription = async () => {
    setConfirmDisabled(true);
    setSpinnerHidden(false);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `/api/restore`, {
      audience: env.AUD,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    }).then((response) => {
      if (!response.ok) {
        handleClose();
        setShowCardError(true);
      } else {
        handleClose();
        setSpinnerHidden(true);
      }
    });
  };

  const storageCost = 4 * props.user.storage.quantity;
  let subCost = 0;

  if (props.user.subscription.plan.name == "BASIC") {
    subCost = 4;
  } else if (props.user.subscription.plan.name == "PRO") {
    subCost = 8;
  } else {
    subCost = 12;
  }

  const total = subCost + storageCost;

  return (
    <Row>
      <Col sm={2} />
      <Col sm={8}>
        <h1>Your subscription has ended!</h1>
        <Alert variant="danger" hidden={!showCardError}>
          There was an issue reactivating your account. Please check your <b>DEFAULT</b> payment method is set in{" "}
          <a onClick={() => handleStripePortalClick()} href="">
            Stripe
          </a>
          . If issue persists, please contact <a href="mailto:support@moltenhosting.com">support</a>.
        </Alert>
        <Table striped bordered hover>
          <thead>
            <th>
              Reactivate?&emsp;
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`tooltip`}>
                    If you would like to downgrade your plan please contact{" "}
                    <a href="mailto:support@moltenhosting.com">support@moltenhosting.com</a>.
                  </Tooltip>
                }
              >
                <FaInfoCircle onMouseEnter={() => setShow(!show)} />
              </OverlayTrigger>
            </th>
            <th></th>
          </thead>
          <tbody>
            <tr>
              <td>{props.user.subscription.plan.name} Subscription</td>
              <td>${subCost}/mo</td>
            </tr>
            <tr>
              <td>Storage Expansion ({props.user.storage.quantity})</td>
              <td>${storageCost}/mo</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>${total}/mo</td>
            </tr>
            <tr>
              <td colSpan="2">
                <Button variant="success" onClick={() => setShowConfirmModal(true)}>
                  Reactivate
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <hr></hr>
        <h1>Final game data exports:</h1>
        <Table striped bordered hover>
          <thead>
            <th>Server Name</th>
            <th>Export Download Link</th>
          </thead>
          <tbody>{stackElements}</tbody>
        </Table>
      </Col>
      <Col sm={2} />
      <Modal show={showConfirmModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to reactivate your subscription?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b hidden={!spinnerHidden}>
            You will be charged ${total} immediately and then ${total} on a recurring monthly basis.
          </b>
          <Spinner hidden={spinnerHidden} animation="border" role="status" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="success" disabled={confirmDisabled} onClick={reactivateSubscription}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </Row>
  );
}

export default withAuthenticationRequired(Dataonly);
