import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import slugify from "slugify";
import env from "../env";
import FoundryVersionPickerComponent from "./foundryVersionPickerComponent";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";

const placeholder = "<PLACEHOLDER>";

export default function NewServerForm(props) {
  const [foundryVersion, setFoundryVersion] = useState("v12");
  const [serverName, setServerName] = useState(placeholder);
  const [region, setRegion] = useState("us-west-2");
  const [formValidationErrors, setFormValidationErrors] = useState(false);
  const [formValidationMessage, setFormValidationMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const [redirectToStack, setRedirectToStack] = useState(false);

  const cfnPattern = "^[a-zA-Z][-a-zA-Z0-9]*";
  const cfnRegex = RegExp(cfnPattern);

  const handleNewStackSubmit = async (e) => {
    e.preventDefault();

    if (!cfnRegex.test(serverName)) {
      setFormValidationErrors(true);
      setFormValidationMessage(`Must start with a letter and match the pattern ${cfnPattern}.`);

      return;
    }

    if (serverName.length < 4 || serverName.length > 30) {
      setFormValidationErrors(true);
      setFormValidationMessage(`Stack names must be between 4 and 30 characters.`);

      return;
    }

    if (serverName === placeholder) {
      setFormValidationErrors(true);
      setFormValidationMessage(`Please enter a server name.`);

      return;
    }

    setSubmitted(true);
    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    const response = await fetch(env.API_URL + "api/servers", {
      audience: env.AUD,
      method: "POST",
      body: JSON.stringify({
        foundryVersion: foundryVersion,
        serverName: serverName,
        region: region
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!response.ok) {
      if (response.status === 409) {
        setFormValidationErrors(true);
        setFormValidationMessage(`Server name ${serverName} is already in use.`);
      } else {
        setFormValidationErrors(true);
        setFormValidationMessage(`An unknown error occurred.  Please contact support.`);
      }
      setSubmitted(false);
      return;
    }

    await new Promise((r) => setTimeout(r, 3000));
    props.getStacks();
    await new Promise((r) => setTimeout(r, 2000));
    setRedirectToStack(true);
    setSubmitted(false);
  };

  const handleInputChange = async (e) => {
    let inputName = e.target.name;
    let value = e.target.value;

    console.log(e);
    console.log(e.target);

    if (inputName === "serverNameInput") {
      inputName = "serverName";
      value = slugify(value, {
        lower: true,
        strict: true
      });
      setServerName(value);
    } else if (inputName === "region") {
      setRegion(value);
    } else {
      setFoundryVersion(value);
    }
  };

  const renderSubmitButton = () => {
    const buttonProps = { disabled: submitted };

    let buttonContent = "Submit";
    let result = null;

    if (submitted) {
      buttonContent = <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />;
    }

    const button = (
      <Button variant="info" type="submit" onClick={handleNewStackSubmit} {...buttonProps}>
        {buttonContent}
      </Button>
    );

    if (props.stacks.length >= props.user.subscription.plan.stacks) {
      // result = (
      //   <OverlayTrigger placement="right" overlay={<Tooltip>You have the maximum number of servers.</Tooltip>}>
      //     <span className="d-inline-block">{button}</span>
      //   </OverlayTrigger>
      // );
      result = button;
    } else {
      result = button;
    }

    return result;
  };

  return (
    <>
      {props.stacks.length >= props.user.subscription.plan.stacks ? <Redirect to={`/`} /> : null}
      {redirectToStack ? <Redirect to={`/server/${serverName}`} /> : null}
      <Form>
        <Form.Group as={Row}>
          <Form.Label column lg={3}>
            Foundry Version
          </Form.Label>
          <Col lg={9}>
            <FoundryVersionPickerComponent selectedVersion={foundryVersion} changeHandler={handleInputChange} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column lg={3}>
            Server Name
          </Form.Label>
          <Col lg>
            <InputGroup>
              <Form.Control
                name="serverNameInput"
                type="text"
                placeholder="Enter server name"
                onChange={handleInputChange}
              />
            </InputGroup>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column lg={3}>
            Region
          </Form.Label>
          <Col lg>
            <InputGroup>
              <Form.Control as="select" name="region" onChange={handleInputChange}>
                <option value="us-east-2" selected>
                  US East 2 (Ohio)
                </option>
                <option value="eu-central-1">EU Central (Frankfurt)</option>
                <option value="ap-southeast-2">South-East Asia Pacific (Sydney)</option>
                <option value="us-west-2">US West (Oregon)</option>
                <option value="sa-east-1">South America (Sao Paulo)</option>
              </Form.Control>
            </InputGroup>
          </Col>
        </Form.Group>
        <InputGroup>
          <Form.Control placeholder={serverName} disabled />
          <InputGroup.Append>
            <InputGroup.Text id="basic-addon2">.moltenhosting.com</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Form.Text className="text-muted">
          Server names must start with a letter, be globally unique, and between 4 and 30 characters
        </Form.Text>
        <br />
        {formValidationErrors ? (
          <Alert variant="danger" onClose={() => setFormValidationErrors(false)} dismissible>
            {formValidationMessage}
          </Alert>
        ) : null}
        {renderSubmitButton()}
      </Form>
    </>
  );
}
