import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import env from "../env";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const JUST_STARTED = "JUST_STARTED";
const INACTIVE = "INACTIVE";
const STARTING = "STARTING";
const NOT_RUNNING = "NOT_RUNNING";
const RUNNING = "RUNNING";
const STOPPING = "STOPPING";

export default function Lobby(props) {
  let query = useQuery();

  let statusParam = query.get("status") || NOT_RUNNING;
  let server = query.get("server");

  const [status, setStatus] = useState(statusParam);
  const [progress, setProgress] = useState(0);
  const [licensePageCount, setLicensePageCount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [serverPassword, setServerPassword] = useState(null);
  const [formValidationErrors, setFormValidationErrors] = useState(false);

  const handleWakeClick = async () => {
    setSubmitted(true);
    fetch("https://" + server + ".moltenhosting.com/start-server", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ code: serverPassword })
    }).then((response) => {
      if (response.status === 403) {
        setFormValidationErrors(true);
      } else if (response.status === 200) {
        setStatus(JUST_STARTED);
      } else if (response.status === 422) {
        setStatus(INACTIVE);
      } else {
        props.errorHandler("An unknown error occured. Please contact support.");
      }

      setSubmitted(false);
    });
  };

  const handleReadyStatus = () => {
    setStatus(RUNNING);
    setProgress(100);
    setTimeout(() => {
      window.location =
        query.get("host") === null ? `https://${server}.moltenhosting.com/` : `https://${query.get("host")}/`;
    }, 5000);
  };

  const getStatus = async () => {
    fetch(env.API_URL + `api/server_status?server=${server}`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json"
      }
    }).then(async (response) => {
      const responseBody = await response.json();
      if (responseBody.status === RUNNING) {
        handleReadyStatus();
      } else if (responseBody.status === NOT_RUNNING && status !== JUST_STARTED) {
        setProgress(0);
        setStatus(NOT_RUNNING);
      } else if (responseBody.status === STARTING) {
        if (progress <= 90) {
          setProgress((previousValue) => previousValue + 5);
        }
        setStatus(STARTING);
      } else if (responseBody.status === STOPPING) {
        setStatus(STOPPING);
        setProgress(0);
      } else {
        props.errorHandler("An unknown error occured. Please contact support.");
      }
    });
  };

  useEffect(() => {
    let timer = setInterval(getStatus, 10000);

    return function cleanup() {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (status === JUST_STARTED) {
      setProgress(10);
      setTimeout(getStatus, 10000);
    } else {
      getStatus();
    }
  }, ["static"]);

  const renderProgressBar = () => {
    return (
      <Row>
        <Col xs={3}></Col>
        <Col xs={6}>
          <ProgressBar animated now={progress} striped />
        </Col>
        <Col xs={3}></Col>
      </Row>
    );
  };

  const renderNotRunning = () => {
    const renderSubmitButton = () => {
      const buttonProps = { disabled: submitted || serverPassword == null };

      let buttonContent = "Submit";

      if (submitted) {
        buttonContent = <Spinner as="span" animation="border" size="lg" role="status" aria-hidden="true" />;
      }

      const button = (
        <Button variant="info" type="submit" onClick={handleWakeClick} {...buttonProps}>
          {buttonContent}
        </Button>
      );

      return button;
    };

    return (
      <Row>
        <Col xs={4}></Col>
        <Col xs={4}>
          <Form.Group controlId="formServerPassword">
            <Form.Label>Enter Server Startup Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Startup Password"
              onChange={(e) => setServerPassword(e.target.value)}
            />

            {formValidationErrors ? (
              <Alert variant="danger" onClose={() => setFormValidationErrors(false)} dismissible>
                Invalid password
              </Alert>
            ) : null}
            <Form.Text muted>
              If you are the server admin, it can be found{" "}
              <Link to={"/server/" + server} target="_blank">
                here
              </Link>
            </Form.Text>
          </Form.Group>
          {renderSubmitButton()}
        </Col>
        <Col xs={4}></Col>
      </Row>
    );
  };

  const renderJustStarted = () => {
    return (
      <div>
        <h1>We just started your server!</h1>
        <h4>This page will let you know once the server is ready.</h4>
        {renderProgressBar()}
      </div>
    );
  };

  const renderStarting = () => {
    return (
      <div>
        <h1>Your server is in the middle of starting up.</h1>
        <h4>This page will let you know once the server is ready.</h4>
        {renderProgressBar()}
      </div>
    );
  };

  const renderRunning = () => {
    return (
      <div>
        <h1>Your server is ready</h1>
        <h4>
          This page will take you to your server in 5 seconds or{" "}
          <a href={"https://" + server + ".moltenhosting.com"}>click here</a> if it doesn't
        </h4>
        {renderProgressBar()}
      </div>
    );
  };

  const renderStopping = () => {
    return (
      <div>
        <h1>Your server is currently stopping</h1>
        <h4>This page will let you know when your server is ready to be started again</h4>
      </div>
    );
  };

  const renderInactive = () => {
    return (
      <div>
        <h2>The server is currently inactive.</h2>
        <h4>Ask the owner to activate the server.</h4>
      </div>
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case NOT_RUNNING:
        return renderNotRunning();
      case JUST_STARTED:
        return renderJustStarted();
      case STARTING:
        return renderStarting();
      case RUNNING:
        return renderRunning();
      case STOPPING:
        return renderStopping();
      case INACTIVE:
        return renderInactive();
    }
  };

  return (
    <Row>
      <Col xs={3}></Col>
      <Col xs={6}>{renderStatus(status)}</Col>
      <Col xs={3}></Col>
    </Row>
  );
}
