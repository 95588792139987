import React from "react";
import env from "../env";
import moment from "moment";
import IndexComponent from "./indexComponent";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import Purchase from "../purchase/purchase";
import Spinner from "react-bootstrap/Spinner";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import RouteDetails from "./routeDetailsComponent";
import NewStack from "./newStackComponent";
import Storage from "./storage";

export const history = createBrowserHistory();

const stripePromise = loadStripe(env.STRIPE_KEY);

function Management(props) {
  if (props.user.subscription && props.user.subscription.subscriptionStatus === "CANCELLED") {
    props.warningHandler(
      "Your subscription is no longer active, you have until " +
        moment.unix(props.user.subscription.finishedAt).local().format("MMMM D, YYYY") +
        " to retrieve your game data"
    );
  }

  if (props.loading) {
    return (
      <Spinner animation="border" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    );
  } else {
    return (
      <Router history={history}>
        <Switch>
          <Route path="/purchase">
            <Elements stripe={stripePromise}>
              <Purchase user={props.user} getUser={props.getUser} />
            </Elements>
          </Route>
          {env.SUBSCRIPTION_REQUIRED && props.user.subscription === null ? <Redirect to="/purchase" /> : null}
          <Route path="/server/:stackName">
            <Elements stripe={stripePromise}>
              <RouteDetails stacks={props.stacks} getStacks={props.getStacks} user={props.user} />
            </Elements>
          </Route>
          <Route path="/new">
            <NewStack getStacks={props.getStacks} stacks={props.stacks} user={props.user} />
          </Route>
          <Route path="/storage">
            <Storage user={props.user} getUser={props.getUser} stacks={props.stacks} />
          </Route>
          <Route path="/">
            <IndexComponent
              spin={props.stacksLoading}
              stacks={props.stacks}
              user={props.user}
              getUser={props.getUser}
              getStacks={props.getStacks}
            />
          </Route>
        </Switch>
      </Router>
    );
  }
}

export default withAuthenticationRequired(Management);
