import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useAuth0 } from "@auth0/auth0-react";
import env from "../env";
import moment from "moment";
import Alert from "react-bootstrap/esm/Alert";

export default function Storage(props) {
  const { getAccessTokenSilently } = useAuth0();

  const [storageQuantity, setStorageQuantity] = useState(1);
  const [purchaseButtonDisabled, setPurchaseButtonDisabled] = useState(false);

  const handleSelectChange = (e) => {
    setStorageQuantity(parseInt(e.target.value));
  };

  const addStorage = async (e) => {
    e.preventDefault();
    setPurchaseButtonDisabled(true);

    const accessToken = await getAccessTokenSilently({ audience: env.AUD });
    await fetch(env.API_URL + `api/storage`, {
      audience: env.AUD,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify({
        quantity: storageQuantity
      })
    });

    props.user.storage.quantity = props.user.storage.quantity + storageQuantity;

    setPurchaseButtonDisabled(false);

    props.user.storage.lastUpdated = moment();
  };

  let base = 0;

  if (props.user.subscription.plan.name === "BASIC") {
    base = 5;
  } else if (props.user.subscription.plan.name === "PRO") {
    base = 10;
  } else if (props.user.subscription.plan.name === "ELITE") {
    base = 20;
  }

  const recentExpansion =
    props.user.storage && props.user.storage.lastUpdated && moment().diff(props.user.storage.lastUpdated, "hours") < 6;

  return (
    <Row>
      <Col></Col>
      <Col xs={6}>
        <h3>Storage</h3>

        <br />
        <br />
        <Table striped bordered hover>
          <tbody>
            <tr>
              <th>Storage From Plan ({props.user.subscription.plan.name})</th>
              <td>{base}</td>
            </tr>
            <tr>
              <th>Expansion ({props.user.storage ? props.user.storage.quantity : 0} x 10)</th>
              <td>{(props.user.storage ? props.user.storage.quantity : 0) * 10}</td>
            </tr>
            <tr>
              <th>Total</th>
              <td>{(props.user.storage ? props.user.storage.quantity * 10 : 0) + base}</td>
            </tr>
          </tbody>
        </Table>
        <br />
        <ul className="text-left">
          <li>For ELITE members, storage is applied to both of your servers.</li>
          <li>
            After purchasing, it will take about 20 minutes for the volume to resize. The next time you start your
            server the space will be applied.
          </li>
        </ul>
        <br />
        <Card>
          <Card.Header>
            <b>Add More Storage</b>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>You can increase storage at 10GB increments for an additional $4/mo.</Form.Label>
                <Form.Control as="select" onChange={handleSelectChange}>
                  <option value="1">10 GB (+$4/mo)</option>
                  <option value="2">20 GB (+$8/mo)</option>
                  <option value="3">30 GB (+$12/mo)</option>
                  <option value="4">40 GB (+$16/mo)</option>
                  <option value="5">50 GB (+$20/mo)</option>
                </Form.Control>
              </Form.Group>
            </Form>
            {recentExpansion ? (
              <Alert variant="warning">You can only expand storage once every six hours.</Alert>
            ) : null}
            <Button variant="success" onClick={addStorage} disabled={purchaseButtonDisabled || recentExpansion}>
              {purchaseButtonDisabled ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                "Purchase"
              )}
            </Button>
          </Card.Body>
        </Card>
      </Col>
      <Col></Col>
    </Row>
  );
}
