import React from "react";
import { useParams } from "react-router-dom";
import StackDetails from "./stackDetails";
import Spinner from "react-bootstrap/Spinner";

export default function RouteDetails(props) {
  let { stackName } = useParams();

  const stack = props.stacks.find((x) => x.stackName === stackName);

  return (
    <>
      {stack === undefined ? (
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      ) : (
        <>
          <StackDetails stacks={props.stacks} stack={stack} getStacks={props.getStacks} user={props.user} />
        </>
      )}
    </>
  );
}
